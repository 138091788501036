// import eth from '../../img/eth.png';

const CancelSec = ({
  cancelHandler,
  index,
  eth,
  price}) => {
    
  return (
    <div className="row">
      
      <div className="col-12">
        <div class="card__info v2">
          <p class="card__artist subtitle mb-0">Item Price</p>
          <p class="card__value highlight mb-0">{`${price} ETH`}</p>
        </div>
      </div>

      <div className="col-12 mx-auto">
        <button onClick={cancelHandler} value={index} className="subscribe__btn pink">CANCEL</button>
      </div>
    </div>
  );
}

export default CancelSec;