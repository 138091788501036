import {useState , useContext} from "react"
import { formatPrice } from '../../utils';
import Web3Context from '../../store/web3-context';
import MarketplaceContext from '../../store/marketplace-context'
import MsgAlert from "../MsgAlert";
import {iconsNote} from "../../utils/allImgs"

const ClaimFunds = ({img}) => {

  const [fundsLoading, setFundsLoading] = useState(false);
  const web3Ctx = useContext(Web3Context);
  const marketplaceCtx = useContext(MarketplaceContext);

  const claimFundsHandler = () => {
    marketplaceCtx.contract.methods.claimFunds().send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {
      setFundsLoading(true);
    })
    .on('error', (error) => {
      document.getElementById("root").style.position = "relative"
      setTimeout(() => {
        setFundsLoading(false);
      },3000)
      return(
        <MsgAlert
          img={iconsNote}
          title='Something went wrong when pushing to the blockchain'
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!"
          addSucMsg={true}
          addOverlay="addOverlay"
          overLayAlertMsg={true}
        />
      )
    });
  };

  // Event ClaimFunds subscription 
  marketplaceCtx.contract.events.ClaimFunds()
  .on('data', (event) => {
    marketplaceCtx.loadUserFunds(marketplaceCtx.contract, web3Ctx.account);
    setFundsLoading(false);
  })
  .on('error', (error) => {
    console.log(error);
  });

  return (
    <div className="claim_funds align-items-center">
      <div className="claim_icon">
        <img loading="lazy" data-src={img} alt="celebration" width={80} />
      </div>
      <div className="claim_info">
        <h4>Claim Your Funds</h4>
        <p>Congrats, You Made A Sale.</p>
        {marketplaceCtx.userFunds > 0 && !fundsLoading && (
          <button
            type="submit"
            onClick={claimFundsHandler}
            className="subscribe__btn">
              {`CLAIM ${formatPrice(marketplaceCtx.userFunds)} ETH`}
          </button>
        )}
      </div>
    </div>
  );
}

export default ClaimFunds;