import {
	astronauta1,
	astronauta2,
	astronauta3,
	arrowWhite

} from '../../utils/allImgs'

const data = [
	{
		NewNFTs:{
			popularOfTheWeek__titleHighlight:"Explore ",
			popularOfTheWeek__title:" Category NFTs",
			popularOfTheWeekList:[
				{
					title:"astronauta 1 NFT",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"0xebFff...0cA3f0C",
					CategoryKey:"Item Category",
					CategoryVal:"NFT Art",
					NftImgWrapper:astronauta1,
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				},
				{
					title:"astronauta 2 NFT",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"0xB4BB8...6913c79",
					CategoryKey:"Item Category",
					CategoryVal:"Collectabiles",
					NftImgWrapper:astronauta2,
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				},
				{
					title:"astronauta 3 NFT",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"7aeu342...y5A3712",
					CategoryKey:"Item Category",
					CategoryVal:"Game Cards",
					NftImgWrapper:astronauta3,
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				}
			]
		}
	}
]



export default data