import dataOwnerImgs from '../data/data-containers/ownerImgs.js'

function onloadAnimation() {
    document.querySelectorAll('img').forEach((img, index) => {
      if (img.getBoundingClientRect().top < window.innerHeight) {
        img.src = img.getAttribute('data-src')
      }
    })
  }

const DECIMALS = (10**18);

const ether = wei => wei / DECIMALS;

const formatPrice = (price) => {
  const precision = 100; // Use 2 decimal places

  price = ether(price);
  price = Math.round(price * precision) / precision;
   
  return price;
};


function Addshrink() {
    let RelBanner = document.querySelector('#banner');

    window.addEventListener("scroll", e => {
        if(window.pageYOffset > 86){
          RelBanner.classList.add("shrink");
        }else
        {
            RelBanner.classList.remove("shrink");
        }
    });
}

function loader() {
    var fadeTarget = document.getElementById("preloader");

    function fadeOutEffect() {
        
        var fadeEffect = setInterval(function () {
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
                fadeTarget.style.display = 'none'
            }
        }, 100);
    }

    window.onload = setTimeout(fadeOutEffect , 1000)
}

function cutAddress(Address) {
  let setHandelAddress = "";
  if(Address){
    let AddressEnd , AddressFull, AddressLeft , AddressRight;
    AddressFull = Address;
    AddressLeft = AddressFull?.slice(0,5);
    AddressRight = AddressFull?.slice(20 , -17);
    AddressEnd = `${AddressLeft}...${AddressRight}`
    setHandelAddress = AddressEnd
  }

  return setHandelAddress
}

function handleRandomImg(setRandomOwnerImg) {
  let randomImg = Math.floor(Math.random() * 10)
  setRandomOwnerImg(dataOwnerImgs[randomImg])
}


const makeOfferHandler = (event, id, key , web3 , priceRefs , account , marketplaceCtx , collectionCtx) => {
  event.preventDefault();

  const enteredPrice = web3.utils.toWei(priceRefs.current[key].current.value, 'ether');

  collectionCtx.contract.methods.approve(marketplaceCtx.contract.options.address, id).send({ from: account })
  .on('transactionHash', (hash) => {
    marketplaceCtx.setMktIsLoading(true);
  })
  .on('receipt', (receipt) => {      
    marketplaceCtx.contract.methods.makeOffer(id, enteredPrice).send({ from: account })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      marketplaceCtx.setMktIsLoading(false);
    }); 
  });
};


const buyHandler = (event , marketplaceCtx , account) => {    
  const buyIndex = parseInt(event.target.value);      
  marketplaceCtx.contract.methods.fillOffer(marketplaceCtx.offers[buyIndex].offerId).send({ from: account, value: marketplaceCtx.offers[buyIndex].price })
  .on('transactionHash', (hash) => {
    marketplaceCtx.setMktIsLoading(true);
  })
  .on('error', (error) => {
    window.alert('Something went wrong when pushing to the blockchain');
    marketplaceCtx.setMktIsLoading(false);
  });            
};

const cancelHandler = (event , marketplaceCtx , account) => {    
  const cancelIndex = parseInt(event.target.value);
  marketplaceCtx.contract.methods.cancelOffer(marketplaceCtx.offers[cancelIndex].offerId).send({ from: account })
  .on('transactionHash', (hash) => {
    marketplaceCtx.setMktIsLoading(true);
  })
  .on('error', (error) => {
    window.alert('Something went wrong when pushing to the blockchain');
    marketplaceCtx.setMktIsLoading(false);
  });    
};

export {
    Addshrink,
    loader,
    DECIMALS,
    ether,
    formatPrice,
    cutAddress,
    handleRandomImg,
    makeOfferHandler,
    buyHandler,
    cancelHandler,
    onloadAnimation
};
