import { useContext, useEffect , useState } from 'react';
import { useParams , useLocation } from "react-router-dom";
import Breadcumb from '../../components/Breadcumb'
import DiscoverNFTInDetails from "./DiscoverNFTInDetails"
import data from '../../data/data-containers/data-ItemDetails'
import SEO from "../../components/Seo"

import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import { formatPrice } from '../../utils';

const ItemDetailsContainer = () => {

  let { id } = useParams();
  const location = useLocation();
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);

	const [price, setPrice] = useState(0);
	const [filteredItems, setFilteredItems] = useState(collectionCtx.collection);
  
	useEffect(() => {
    setFilteredItems(collectionCtx.collection.filter(item => item.id == id));	
	},[location]);

	useEffect(() => {
    const handleConter = () => {
      let item = collectionCtx.collection.filter(item => item?.id && item.id == id);
      item.map((item , key) => {
        item.count = item.count + 1
      })
      let oldItems = localStorage.getItem("handleConterItem") ? JSON.parse(localStorage.getItem("handleConterItem")) : []
      let newItemes = [...oldItems , item]
      window.localStorage.setItem("handleConterItem" , JSON.stringify(newItemes))
      
    }
    handleConter()

	},[id]);

	useEffect(() => {
    const handlePrice = async() => {
      const index = await marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id == id) : -1;
      setPrice(index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null)
    }
    handlePrice()
	},[location , id]);

  return (
    <>
      <SEO title="Item Details" />
      <main>
        <Breadcumb title='Item Details' />
        
        {filteredItems ? <DiscoverNFTInDetails price={price} data={filteredItems} /> : <DiscoverNFTInDetails data={data[0].DiscoverNFTInDetails} />}
      </main>
    </>
  );
}

export default ItemDetailsContainer;