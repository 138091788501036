import { useContext, useEffect , useState } from 'react';
import { useParams } from "react-router-dom";
import Breadcumb from '../../components/Breadcumb'
import ExploreNewNFTs from "../../components/ExploreNewNFTs"
import MsgAlert from "../../components/MsgAlert"
import {iconsNote} from "../../utils/allImgs"
import data from '../../data/data-containers/data-Explore'
import SEO from "../../components/Seo"
import CollectionContext from '../../store/collection-context';
import Spinner from '../../components/Spinner';

const CategoryNameContainer = () => {

  const collectionCtx = useContext(CollectionContext);
  const [isCateg , setIsCateg] = useState(false)
  let { categ } = useParams();

  useEffect(() => {
    if(categ){
      setIsCateg(true)
    }else{
      setIsCateg(false)
      setTimeout(() => {
        window.location.href = "/explore"
      },3000)
    }
  },[])

  return (
    <>
      <SEO title="Category Page" />
      <main>
        <Breadcumb title={categ} />
        {isCateg && (
          <>
            {!collectionCtx.nftIsLoading && <ExploreNewNFTs categ={categ} setIsCateg={setIsCateg} filterByCated={true} data={data[0].NewNFTs} />}
            {collectionCtx.nftIsLoading && <Spinner />}
          </>
        )}
        {!isCateg && (
          <MsgAlert
            img={iconsNote}
            addSucMsg={true}
            title="No Items Listed in This Category"
            text="Domain Plug Web3 Domain Marketplace"
          />
        )}
      </main>
    </>
  );
}

export default CategoryNameContainer;