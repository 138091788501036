import { useContext , useEffect } from 'react';
import Breadcumb from '../../components/Breadcumb'
import ExploreNewNFTs from "../../components/ExploreNewNFTs"
import MsgAlert from "../../components/MsgAlert"
import {iconsNote} from "../../utils/allImgs"
import data from '../../data/data-containers/data-Explore'
import SEO from "../../components/Seo"
import CollectionContext from '../../store/collection-context';
import Spinner from '../../components/Spinner';

const ExploreNFTsContainer = () => {

  const collectionCtx = useContext(CollectionContext);

  return (
    <>
      <SEO title="Explore" />
      <main>
        <Breadcumb title='Explore NFTs' />
        {!collectionCtx.nftIsLoading && <ExploreNewNFTs data={data[0].NewNFTs} />}
        {collectionCtx.nftIsLoading && <Spinner />}        
        <MsgAlert
          img={iconsNote}
          title="No Items Listed For Sale Yet!"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!"
        />
      </main>
    </>
  );
}

export default ExploreNFTsContainer;