import {useState , useContext , useEffect , useRef , createRef} from "react"
import moment from 'moment';
import { NavLink } from "react-router-dom";
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import arrowWhite from "../../assets/img/arrow-white.svg"
import {formatPrice , cutAddress , makeOfferHandler , buyHandler , cancelHandler} from "../../utils"
import BuySec from "../../components/BuySec"
import CancelSec from "../../components/CancelSec"
import PreloaderMintItem from "../../components/PreloaderMintItem"
import ItemsNotFound from "../../components/ItemsNotFound"
import AlertDeploy from "../../components/AlertDeploy"
import {iconsNote} from "../../utils/allImgs"
import web3 from '../../connection/web3';
import Web3Context from '../../store/web3-context';
import eth from '../../img/eth.png';

const MyAssetsNewNFTs = ({data}) => {

  const {
    popularOfTheWeek__titleHighlight,
    popularOfTheWeek__title,
    popularOfTheWeekList
  } = data

  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const web3Ctx = useContext(Web3Context);

  const [collection , setCollection] = useState(collectionCtx.collection)
  const [isHaveItems , setIsHaveItems] = useState(true)
  const [account , setAccount] = useState(web3Ctx.account)
  
  useEffect(() => {
    const hanldeCollection = async () => {
      let items = await collectionCtx.collection
      setCollection(items)
    }
    hanldeCollection()
  },[collectionCtx.collection , account])

  useEffect(async() => {
    await setAccount(web3Ctx.account)
  },[web3Ctx.account , collection])

  useEffect(() => {
    
    if(collectionCtx.collection.every((currentValue) => currentValue.addressOwner !== account)) {
      setIsHaveItems(false)
      console.log("yes isHaveItems is false")
    }else{
      setIsHaveItems(true)
      console.log("yes isHaveItems is true")
    }
  },[collection])

  const priceRefs = useRef([]);
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length).fill().map((_, i) => priceRefs.current[i] || createRef());
  }

  console.log("isHaveItems" , isHaveItems)
  
  return (
      <section className="popularOfTheWeek">
        <h2 className="popularOfTheWeek__title text-center">
          <span className="highlight">{popularOfTheWeek__titleHighlight}</span>{popularOfTheWeek__title}
        </h2>
        <div className="row">
          {collectionCtx.collection.length !== 0 ? !marketplaceCtx.mktIsLoading ? isHaveItems ? collection.slice(0,3).map((item , key) => {
            let setHandelAddress = cutAddress(item.addressOwner)
            const index = marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id === item.id) : -1;
            const owner = index === -1 ? item.owner : marketplaceCtx.offers[index].user;
            const price = index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null;
            const dateTimeAgo = moment(item.time ? item.time : new Date()).fromNow();
            console.log("item" , item)
            return(
              <>
                {item.addressOwner === account ?
              
                  <div className="col-lg-3 col-md-6" key={key} data-aos="fade-up">
                    
                  <div className="popular__card">
                    <NavLink to={`/item-details/${item.id}`}>
                      <div className="card__header">
                        <p>{item.title}</p>
                        <img loading="lazy" data-src={arrowWhite} alt="link astronauta 1" className="card__link" />
                      </div>
                      <div className="card__info">
                        <p className="card__artist subtitle">Item Owner</p>
                        <p className="card__value highlight">{setHandelAddress}</p>
                      </div>
                      <div className="card__info">
                        <p className="card__artist subtitle">Item Category</p>
                        <p className="card__value highlight">{item.category}</p>
                      </div>
                      <div className="nft-img-wrapper">
                        <img loading="lazy" data-src={`https://api.filebase.io/v1/ipfs/${item.img}`} alt="astronauta 1 NFT" />
                      </div>
                      </NavLink>
                      {index !== -1 ?
                        item.addressOwner !== account ?
                          <BuySec
                            buyHandler={(event) => buyHandler(event , marketplaceCtx , account)}
                            index={index}
                            eth={eth}
                            price={price}
                          /> :
                          <CancelSec
                            cancelHandler={(event) => cancelHandler(event , marketplaceCtx , account)}
                            index={index}
                            eth={eth}
                            price={price}
                          /> :
                        item.addressOwner === account ?
                        <>
                        <form className="row card__info" onSubmit={(e) => makeOfferHandler(e, item.id, key , web3 , priceRefs , account , marketplaceCtx , collectionCtx)}>
                          
                          <div className="col-12 form-group v2">
                            <input
                              type="number"
                              step="0.01"
                              placeholder="ETH..."
                              className="form-control"
                              ref={priceRefs.current[key]}
                            />
                          </div>  
                          <div className="col-12">
                            <button type="submit" className="subscribe__btn pink">OFFER</button>
                          </div>                                
                        </form>
                      </>
                      : <span className="d-none"></span> }
                    </div>
                    

                  </div>
                : <ItemsNotFound />}
              </>
            )
          }) : <AlertDeploy img={iconsNote} title="You Don't Own Any NFTs Yet!" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!" addSucMsg={true} addJustify={true} /> : Array(8).fill().map((_i, key) => <PreloaderMintItem key={key} />) : <AlertDeploy img={iconsNote} title="You Don't Own Any NFTs Yet!" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!" addSucMsg={true} addJustify={true} />}
        </div>
      </section>
  );
}

export default MyAssetsNewNFTs;