const BuySec = ({
  buyHandler,
  index,
  eth,
  price}) => {
    
  return (
    <div className="row">
      
      <div className="col-12">
        <div class="card__info v2">
          <p class="card__artist subtitle mb-0">Item Price</p>
          <p class="card__value highlight mb-0">{`${price} ETH`}</p>
        </div>
      </div>
      <div className="col-12 mx-auto">
        <button onClick={buyHandler} value={index} className="subscribe__btn pink">BUY NOW</button>
      </div>
    </div> 
  );
}

export default BuySec;