import { useState, useContext , useEffect } from 'react';
import Web3Context from '../../../store/web3-context';
// import {handleRandomImg} from "../../../utils"
import CollectionContext from '../../../store/collection-context';


const ipfsClient = require('ipfs-http-client');

const projectId = 'domainplug';   // <---------- your Infura Project ID

const projectSecret = 'MDE1RDRGRTdCOEJGQUUxMkI2RUE6eGJ2YnRMakNLVmh5NnU2eUhaN3AwUDdocGFyaG1razFaa3lXS1BHcjpkb21haW5wbHVn';  // <---------- your Infura API Key Secret

const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient.create({
  host: 'https://api.filebase.io/v1/ipfs',
  port: 5001,
  protocol: 'https',
  headers: {
      authorization: auth,
  },
});

//const ipfs = ipfsClient.create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });

const MintForm = ({
  imgMint,
  imgBadge,
  data,
  setHandleAddSucMsg
}) => {

  const {
    title,
    titleHighlight,
    mintingForm,
    nftCategoryTitle,
    nftCategory,
    subscribe__btn
  } = data


  const [enteredName, setEnteredName] = useState('');
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);

  const [enteredDescription, setEnteredDescription] = useState('');
  const [nameIsValid, setNameIsValid] = useState(true);

  const [capturedFileBuffer, setCapturedFileBuffer] = useState(null);
  const [fileIsValid, setFileIsValid] = useState(true);

  const [category, setCategory] = useState('');
  const [secImgItem, setSecImgItem] = useState('');
  // const [addSucMsg, setAddSucMsg] = useState(false);
  const [handleSucMsg, setHandleSucMsg] = useState(false);

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

  const enteredNameHandler = (event) => {
    setEnteredName(event.target.value);
    // setTitle(name)
  };

  const enteredDescriptionHandler = (event) => {
    setEnteredDescription(event.target.value);
  };
  
  const captureFile = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      setCapturedFileBuffer(Buffer(reader.result));
      
      const fileAdded = await ipfs.add(Buffer(reader.result));
      if(!fileAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }

      setSecImgItem(fileAdded.path)
      console.log("fileAdded.path" , fileAdded.path)
    }
  };

  const handelCategory = (e) => {
    let val = e.target.value;
    e.preventDefault();
    if(val){
      setCategory(val)
    }
  }
  
  const submissionHandler = (event) => {
    event.preventDefault();

    enteredName ? setNameIsValid(true) : setNameIsValid(false);
    enteredDescription ? setDescriptionIsValid(true) : setDescriptionIsValid(false);
    capturedFileBuffer ? setFileIsValid(true) : setFileIsValid(false);

    const formIsValid = enteredName && enteredDescription && capturedFileBuffer;

    // Upload file to IPFS and push to the blockchain
    const mintNFT = async() => {
      // Add file to the IPFS
      const fileAdded = await ipfs.add(capturedFileBuffer);
      if(!fileAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }

      const metadata = {
        title: "Asset Metadata",
        type: "object",
        properties: {
          name: {
            type: "string",
            description: enteredName
          },
          description: {
            type: "string",
            description: enteredDescription,
            date:new Date(),
            count:0,
            addressOwner:web3Ctx.account
          },
          category:{
            type: "string",
            category: category
          },
          image: {
            type: "string",
            description: fileAdded.path
          }
        }
      };

      const metadataAdded = await ipfs.add(JSON.stringify(metadata));
      if(!metadataAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }
      
      collectionCtx.contract.methods.safeMint(metadataAdded.path).send({ from: web3Ctx.account })
      // setAddSucMsg(true)
      .on('transactionHash', (hash) => {
        collectionCtx.setNftIsLoading(true);
      })
      .on("receipt" , () => {
        // setAddSucMsg(true)
        setHandleAddSucMsg(true)
      })
      .on('error', (e) =>{
        window.alert('Something went wrong when pushing to the blockchain');
        collectionCtx.setNftIsLoading(false);  
      })      
    };
    formIsValid && mintNFT();
  };

  console.log("category , collectionCtx , secImgItem" , category , collectionCtx , secImgItem)

  const nameClass = nameIsValid? "form-control" : "form-control is-invalid";
  const descriptionClass = descriptionIsValid? "form-control" : "form-control is-invalid";
  const fileClass = fileIsValid? "form-control" : "form-control is-invalid";
  


  return (
      <section className="minting-section sect-p-80">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="bestArtists__header">
              <h2 className="mb-5">{title} <span className="highlight">{titleHighlight}</span></h2>
              <form action method="POST" onSubmit={submissionHandler} className="minting-form">
                {/* {mintingForm && mintingForm.map((item , key) => (
                  <div className="form-group" key={key}>
                    <label>{item.title}</label>
                    <input type={item.type} className={`form-control ${item.Class}`} id={item.ID} placeholder={item.placeholder} />
                  </div>
                ))} */}
                <div className="form-group">
                  <label>Upload NFT File</label>
                  <input
                    type="file"
                    id="item-file"
                    placeholder="Enter your email"
                    required
                    onChange={captureFile}
                    className={`form-control inputFile ${fileClass}`}
                  />
                </div>
                <div className="form-group">
                  <label>NFT Item Details</label>
                  <input
                    type="text"
                    id="item-file"
                    placeholder="Enter NFT Name"
                    required
                    onChange={enteredNameHandler}
                    value={enteredName}
                    className={`${nameClass} form-control`}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    rows={3}
                    id="item-desc"
                    placeholder="Enter NFT Description"
                    defaultValue={""}
                    required
                    className={`${descriptionClass} form-control`}
                    value={enteredDescription}
                    onChange={enteredDescriptionHandler}
                  />
                </div>
                <div className="form-group">
                  <label>{nftCategoryTitle}</label>
                  <select onClick={(e) => handelCategory(e)} name="cars" id="category" className="nft-category" required>
                    {nftCategory && nftCategory.map((item , key) => (
                      <option key={key} value={item.val} name={item.val}>{item.text}</option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="subscribe__btn">{subscribe__btn}</button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mint-wrapper">
              
              <img className="mint-img mt-s" loading="lazy" data-src={imgMint} alt="astronauta 1 NFT" />
              
              <img loading="lazy" data-src={imgBadge} alt="Emblema Rocket" className="badge-img" />
            </div>
          </div>
        </div>
      </section>
  );
}

export default MintForm;