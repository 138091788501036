const InfosAndNumbers = ({data}) => {

  const {numbers , infoWrapper} = data;

  return (
    <section className="infosAndNumbers">
      <div className="row">
        <div className="col-12 col-lg-3">
          <div className="numbers">
            {numbers && numbers.map((item , key) => (
              <div className="number" key={key}>
                <span className="number__value">{item.val}</span>
                <p className="number__subject subtitle">{item.subject}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-lg-9 info-wrapper">
          <div className="row">
            {infoWrapper && infoWrapper.map((item , key) => (
              <div className="col-12 col-lg-6" key={key}>
                <div className="info">
                  <p className="info__content">
                    {item.textUp}
                    <a href="/#" className="highlight">Quisquam autem</a>
                    {item.textDown}
                  </p>
                </div>
              </div>
            ))}
            <div className="col-12">
              <div className="infosAndNumbers__banner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InfosAndNumbers;