const MsgAlert = ({img="" , title="" , text="" , addSucMsg="" , addOverlay="" , addJustify="" , overLayAlertMsg}) => {

  return (
    <>
      <div className={`${overLayAlertMsg && "overLayAlertMsg"}`}></div>
      <div className={`${addOverlay}`}>
        <div className={`container ${addSucMsg ? "d-block" : "d-none"} `}>
          <div className={`row ${addJustify && "justify-content-center"}`}>
            <div className="col-12 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 message">
              <img loading="lazy" data-src={img} alt="note" width={100} />
              <h4>{title}</h4>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MsgAlert;