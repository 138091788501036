import {useState , useContext , useEffect , useRef , createRef} from "react"
import moment from 'moment';
import { NavLink } from "react-router-dom";
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import arrowWhite from "../../assets/img/arrow-white.svg"
import {formatPrice , cutAddress , makeOfferHandler , buyHandler , cancelHandler} from "../../utils"
import {iconsNote} from "../../utils/allImgs"
import BuySec from "../../components/BuySec"
import CancelSec from "../../components/CancelSec"
import PreloaderMintItem from "../../components/PreloaderMintItem"
import MsgAlert from "../../components/MsgAlert"
import web3 from '../../connection/web3';
import Web3Context from '../../store/web3-context';
import eth from '../../img/eth.png';
import TagButton from "../../components/TagButton"

const MyAssetsNewNFTs = ({data , filterByCated=false , categ="" , setIsCateg}) => {

  const {
    popularOfTheWeek__titleHighlight,
    popularOfTheWeek__title,
    popularOfTheWeekList
  } = data

  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const web3Ctx = useContext(Web3Context);

  const [collection , setCollection] = useState(collectionCtx.collection)
  const [account , setAccount] = useState(web3Ctx.account)
  const [filteredImages, setFilteredImages] = useState(collectionCtx.collection);
  const [tag, setTag] = useState('all');
  
	useEffect(
		() => {

      const hanldeCollection = async () => {
        let items = await collectionCtx.collection
        setCollection(items)
      }

      if(filterByCated){
        const isHaveItems = (currentValue) => currentValue?.filter && currentValue.filter[0] === categ;
        if(collectionCtx.collection.some(isHaveItems)) {
          setFilteredImages(collectionCtx.collection.filter(item => item?.filter && item.filter[0] === categ))
          
        }else{
          setTimeout(() => {
            window.location.href = "/explore"
          },3000)
          setIsCateg(false)
        }
      }else{
        
        tag === 'all' ? setFilteredImages(collectionCtx.collection) : setFilteredImages(collectionCtx.collection.filter(item => item?.filter && item.filter[0] === tag));
      }
      
		},
		[tag , categ , filterByCated , collectionCtx.collection]
	);

  useEffect(() => {
    if(!filterByCated){
      setFilteredImages(collectionCtx.collection)
    }
    
  },[])

  console.log("collection" , collection)

  useEffect(async() => {
    await setAccount(web3Ctx.account)
  },[web3Ctx.account , collection])

  const priceRefs = useRef([]);
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length).fill().map((_, i) => priceRefs.current[i] || createRef());
  }

  return (
      <section className="popularOfTheWeek">
        <h2 className="popularOfTheWeek__title text-center">
          <span className="highlight">{popularOfTheWeek__titleHighlight}</span>{popularOfTheWeek__title}
        </h2>
        {!filterByCated && (
          <div className="dream-projects-menu mb-5">
              <div className="text-center categoryItemsBtns">
                <TagButton name="all" val="all" tagActive={tag === 'all' ? true : false} handleSetTag={setTag} /> /
                <TagButton name="NFT Art" val="NFT" tagActive={tag === 'NFT Art' ? true : false} handleSetTag={setTag} /> /
                <TagButton name="Game Cards" val="Game" tagActive={tag === 'Game Cards' ? true : false} handleSetTag={setTag} /> /
                <TagButton name="Collectabiles" val="Collectabiles" tagActive={tag === 'Collectabiles' ? true : false} handleSetTag={setTag} />
                <TagButton name="Music" val="Music" tagActive={tag === 'Music' ? true : false} handleSetTag={setTag} /> /
                <TagButton name="Domains" val="Domains" tagActive={tag === 'Domains' ? true : false} handleSetTag={setTag} />
                <TagButton name="Purchases" val="Purchases" tagActive={tag === 'Purchases' ? true : false} handleSetTag={setTag} />
              </div>
          </div>
        )}
        <div className="row categoryItems">
          {collectionCtx.collection.length !== 0 ? !marketplaceCtx.mktIsLoading ? filteredImages.length !== 0 ? filteredImages.map((item , key) => {
            let setHandelAddress = cutAddress(item.addressOwner)
            const index = marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id === item.id) : -1;
            const owner = index === -1 ? item.owner : marketplaceCtx.offers[index].user;
            const price = index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null;
            const dateTimeAgo = moment(item.time ? item.time : new Date()).fromNow();
            return(
              <div className={`col-lg-3 col-md-6 categoryItem ${item?.filter && item.filter[0]}`} key={key}>
                
                <div className="popular__card">
                  <NavLink to={`/item-details/${item.id}`}>
                    <div className="card__header">
                      <p>{item.title}</p>
                      <img loading="lazy" data-src={arrowWhite} alt="link astronauta 1" className="card__link" />
                    </div>
                    <div className="card__info">
                      <p className="card__artist subtitle">Item Owner</p>
                      <p className="card__value highlight">{setHandelAddress}</p>
                    </div>
                    <div className="card__info">
                      <p className="card__artist subtitle">Item Category</p>
                      <p className="card__value highlight">{item.category}</p>
                    </div>
                    <div className="nft-img-wrapper">
                        <img loading="lazy" data-src={`https://api.filebase.io/v1/ipfs/${item.img}`} alt="astronauta 1 NFT" />
                    </div>
                    </NavLink>
                    {index !== -1 ?
                      item.addressOwner !== account ?
                        <BuySec
                          buyHandler={(event) => buyHandler(event , marketplaceCtx , account)}
                          index={index}
                          eth={eth}
                          price={price}
                        /> :
                        <CancelSec
                          cancelHandler={(event) => cancelHandler(event , marketplaceCtx , account)}
                          index={index}
                          eth={eth}
                          price={price}
                        /> :
                      item.addressOwner === account ?
                      <>
                      <form className="row card__info" onSubmit={(e) => makeOfferHandler(e, item.id, key , web3 , priceRefs , account , marketplaceCtx , collectionCtx)}>
                      <div className="col-12 form-group v2">
                          <input
                            type="number"
                            step="0.01"
                            placeholder="Enter Item Price, in ETH..."
                            className="form-control"
                            ref={priceRefs.current[key]}
                          />
                        </div> 
                        <div className="col-12">
                          <button type="submit" className="subscribe__btn pink">OFFER</button>
                        </div>
                                                        
                      </form>
                    </>
                    : <div className="price-not">
                        <div className="not-icon">
                          <img data-src={eth} width="50" height="50" className="mr-10" alt="alert icon"></img>
                        </div>
                        <div className="not-info">
                          <h5 className="highlight">NFT Price Not Set</h5>
                          <p className="mb-0">NFT Item Owner does not set price Yet!</p>
                        </div>
                      </div> }
                  </div>
                

              </div>
            )
          }) : <MsgAlert img={iconsNote} title="No NFTs in this Category" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!" addSucMsg={true} addJustify={true} /> : Array(8).fill().map((_i, key) => <PreloaderMintItem key={key} />) : <MsgAlert img={iconsNote} title="No Items Listed For Sale Yet!" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!" addSucMsg={true} addJustify={true} />}
        </div>
      </section>
  );
}

export default MyAssetsNewNFTs;