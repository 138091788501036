import HomeContainer from '../../containers/Home';

// import '../../assets/css/home.css'
// import './home.css'
// import Head from '../../layouts/Head';

// import Footer from '../../layouts/Footer';

const Home = (props) => {

  return <HomeContainer props={props} />
}

export default Home;

