const Headings = ({data}) => {

  const {
    headings__intro,
    headings__avatarsImg,
    headings__avatarsAvatars__text,
    headings__title,
    highlight,
    headings__brand
  } = data

  return (
    <section className="headings">
      <div className="row">
        <div className="col-12 col-lg-5">
          <div className="headings__intro">
            <p>
              {headings__intro}
            </p>
            <div className="headings__avatars">
              <img loading="lazy" data-src={headings__avatarsImg} alt="avatares artistas" width={120} />
              <div className="avatars__text">
                <span>+ 10</span>
                <p>{headings__avatarsAvatars__text}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 mt-s">
          <h1 className="headings__title">
            {headings__title}
            <span className="highlight">{highlight}</span>
          </h1>
        </div>
        <div className="col-12 col-lg-2 mt-s">
          <a href="/explore">
            <img loading="lazy" data-src={headings__brand} alt="Emblema Rocket" className="headings__brand" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Headings;