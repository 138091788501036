import { NavLink } from "react-router-dom";

const Categories = ({data}) => {
  const {
    title,
    titleHighlight,
    CategoriesList
  } = data;
  return (
    <section className="category">
      <div className="-fluid">
        <div className="How__header">
          <h2>{title} <span className="highlight">{titleHighlight}</span></h2>
        </div>
        <div className="row">
          {CategoriesList && CategoriesList.map((item , key) => (
            <div className="col-12 col-lg-2 col-md-4" key={key}>
              <NavLink to={`/category-name/${item.val}`} className="category-item">
                <div className="cat-icon-wrapper">
                  <img loading="lazy" data-src={item.img} alt="Domain Plug Placeholder" width={60} />
                </div>
                <h4>{item.title}</h4>
              </NavLink>
            </div> 
          ))}


        </div>
      </div>
    </section>
  );
}

export default Categories;