import {
	cat1,
	cat2,
	cat3,
	cat4,
	cat5,
	cat6,
	icons1,
	icons2,
	icons3,
	icons4,
	astronauta1,
	astronauta2,
	astronauta3,
	arrowWhite,
	badge,
	groupAvarts,

} from '../../utils/allImgs'

const data = [
	{
		dataHeadings:{
			headings__intro:"Welcome to the Domain Plug Test marketplace for crypto collectibles and non-fungible tokens (NFT). Buy, sell and discover digital assets unique to you.",
			headings__avatarsImg:groupAvarts,
			headings__avatarsAvatars__text:"artistas selecionados",
			headings__title:"Discover, Buy & Sell Web3 Domains, Art, and Collect Various ",
			highlight:"NFTs",
			headings__brand:badge
		},
		NewNFTs:{
			popularOfTheWeek__titleHighlight:"Explore ",
			popularOfTheWeek__title:" New NFTs",
			popularOfTheWeekList:[
				{
					title:"Domain Plug 1 NFT",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"0xebFff...0cA3f0C",
					CategoryKey:"Item Category",
					CategoryVal:"NFT Art",
					NftImgWrapper:astronauta1,
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				},
				{
					title:"testaccount.eth",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"0xB4BB8...6913c79",
					CategoryKey:"Item Category",
					CategoryVal:"Domains",
					NftImgWrapper:astronauta2,
					PriceKey:"Item Price",
					PriceVal:"6.9 ETH"
				},
				{
					title:"Domain Plug Membership Pass",
					card__link:arrowWhite,
					OwnerKey:"Item Owner",
					OwnerVal:"7aeu342...y5A3712",
					CategoryKey:"Item Category",
					CategoryVal:"Game Cards",
					NftImgWrapper:astronauta3,
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				}
			]
		},
		CategoriesDate:{
			title:"Browse NFTs",
			titleHighlight:"by Category",
			CategoriesList:[
				{
					img:cat1,
					title:"NFT Art",
					val:"NFT"
				},
				{
					img:cat2,
					title:"Games",
					val:"Game"
				},
				{
					img:cat3,
					title:"Membership NFTs",
					val:"Membership NFTs"
				},
				{
					img:cat4,
					title:"Music",
					val:"Music"
				},
				{
					img:cat5,
					title:"Domains",
					val:"Domains"
				},
				{
					img:cat6,
					title:"Purchases",
					val:"Purchases"
				}
			]
		},
		HowItWorks:[
			{
				img:icons1,
				artist__name:"Connect Your Wallet",
				artist__photosNumber:"it is required to install Metamask wallet as a browser extension, Then you should configure Metamask to connect to required ETH Network."
			},
			{
				img:icons2,
				artist__name:"Mint Your NFT",
				artist__photosNumber:"Mint your NFT assets with included IPFS with easiest way, just upload and fill your nft info and click the mint button, and Happpy Minting"
			},
			{
				img:icons3,
				artist__name:"List NFTs For Sale",
				artist__photosNumber:"once you click the mint button the metamask wallet will pop up to confirm the minting function, just confirm and your nft will be listed"
			},
			{
				img:icons4,
				artist__name:"Buy and Sell NFTs",
				artist__photosNumber:"once the item is listed, congrats! Now you are ready to sell and buy NFTs, You just need to specify your NFT Price and click offer"
			}
		]
	}
]



export default data