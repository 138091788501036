import {
  logo,
	arrowBlack
} from '../../utils/allImgs'

import data from '../../data/data-layouts/data-Footer.json'
import dataDown from '../../data/data-layouts/data-Footer'

const Img = ({img , alt}) => (
  <img loading="lazy" data-src={img} alt={alt} />
)

function Footer(){

  return(
      <footer>
        <div className="row">
          <div className="col-11">
            <div className="toTop">
              <Img img={logo} alt="Rocket logo" />
            </div>
          </div>
          <div className="col-1">
            <a href="#top">
              <div className="toTop__btn">
                <Img img={arrowBlack} alt="Voltar ao topo" />
              </div>
            </a>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-3 col-md-6">
            <form className="subscribe">
              <h3 className="subscribe__title">
                {data[0].subscribe__title} <span className="highlight">{data[0].subscribe__titleHighlight}</span>
              </h3>
              <input type="text" placeholder="nft@domain.com" className="subscribe__input" />
              <button type="submit" className="subscribe__btn">{data[0].subscribe__btn}</button>
            </form>
          </div>
          {data && data[0].listNavigation.map((itemUp , keyUp) => (
            <div className="col-12 col-lg-3 col-md-6" key={keyUp}>
              <div className="listNavigation__col">
                <ul className="listNavigation__items">
                  <li><h4 className="listNavigation__title">{itemUp.title}</h4></li>
                  {itemUp.list.map((itemDown , keyDown) => (
                    <li key={keyDown}><a href="/#">{itemDown.link}</a></li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="row copy-right">
          <div className="col-12 col-lg-9">
            <p className="subtitle"> {data[0].copyRight}</p>
          </div>
          <div className="col-12 col-lg-3 mt-s">
            <div className="socials">
              {dataDown && dataDown.map((item , key) => (
                <a key={key} href={item.path} rel="noreferrer" target="_blank"><Img img={item.img} alt={item.alt} /></a>
              ))}
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer