import {
	instagram,
	youtube,
	twitter,
	email
}from "../../utils/allImgs"

const data = [
    {
        path:"#",
        img:instagram,
        alt:"link instagram"
    },
    {
        path:"#",
        img:youtube,
        alt:"link youtube"
    },
    {
        path:"#",
        img:twitter,
        alt:"link twitter"
    },
    {
        path:"mailto:info@domain.com",
        img:email,
        alt:"link email"
    }
]

export default data