import {
	astronauta1,
	iconsD1,
	iconsD2,
	iconsD3,
	iconsD4,
	iconsD5,
	arrowWhite

} from '../../utils/allImgs'

const data = [
	{
		DiscoverNFTInDetails:{
			popularOfTheWeek__titleHighlight:"Discover ",
			popularOfTheWeek__title:" NFT in Details",
            nftImgWrapperImg:astronauta1,
            nft_name:"astronauta 1 NFT",
            nft_nameImg:arrowWhite,
            nft_desc:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore distinctio at nobis saepe, molestiae a, dicta similique.",
			detailsBlock:[
				{
					img:iconsD1,
					title:"Item Owner",
					text:"0xebFff...0cA3f0C"
				},
				{
					img:iconsD2,
					title:"Item Category",
					text:"Collectibles"
				},
				{
					img:iconsD3,
					title:"NFT Asset Size",
					text:"300x300"
				},
				{
					img:iconsD4,
					title:"Creation Date",
					text:"04 April 2022"
				}
			],
            PriceItem:"Item Price",
            details_icon:iconsD5,
            details_infoTitle:"Current NFT Price",
            details_infoHighlight:"0.032 ETH Or Price Not Set Yet!"
		}
	}
]



export default data