import { useContext, useState , useEffect } from 'react';
import CollectionContext from '../../store/collection-context';
import Web3Context from '../../store/web3-context';
import MarketplaceContext from '../../store/marketplace-context';
import web3 from '../../connection/web3';

import { NavLink } from "react-router-dom";
import {
    logo,
	logo_MetaMask
} from '../../utils/allImgs'
import data from '../../data/data-layouts/data-Head.json'
import {cutAddress , handleRandomImg} from '../../utils'

function Head(){
  const [fundsLoading, setFundsLoading] = useState(false);
  const [randomOwnerImg , setRandomOwnerImg] = useState("")
  const [handelAddress , setHandelAddress] = useState("")
  
  const web3Ctx = useContext(Web3Context);
  const marketplaceCtx = useContext(MarketplaceContext);
  const collectionCtx = useContext(CollectionContext);

  useEffect(() => {
      handleRandomImg(setRandomOwnerImg)
  },[])

  useEffect(() => {
    web3Ctx.account && setHandelAddress(cutAddress(web3Ctx.account))
    
  },[web3Ctx.account])
  
  const connectWalletHandler = async() => {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }

    // Load accounts
    web3Ctx.loadAccount(web3);
  };

  // Event ClaimFunds subscription 
  marketplaceCtx?.contract?.events?.ClaimFunds()
  .on('data', (event) => {
    marketplaceCtx.loadUserFunds(marketplaceCtx.contract, web3Ctx.account);
    setFundsLoading(false);
  })
  .on('error', (error) => {
    console.log(error);
  });

  let etherscanUrl;

  if(web3Ctx.networkId === 3) {
    etherscanUrl = 'https://ropsten.etherscan.io'
  } else if(web3Ctx.networkId === 4) {
    etherscanUrl = 'https://rinkeby.etherscan.io'
  } else if(web3Ctx.networkId === 5) {
    etherscanUrl = 'https://goerli.etherscan.io'
  } else {
    etherscanUrl = 'https://etherscan.io'
  }

  const showHead = web3 && collectionCtx.contract && marketplaceCtx.contract;


  return(
    <div className="header header-fixed">
        <div className="navbar">
            <div className="logo">
                <a href="/#"><img loading="lazy" data-src={logo} alt="Rocket logo" className="logo" /></a>
            </div>
            <input type="checkbox" id="navbar-toggle" />
            <label htmlFor="navbar-toggle"><i /></label>
            <nav className="menu">
                <ul>
                    {data && data[0].menuLinks.map((item , key) => (
                        <li key={key}><NavLink to={item.path}>{item.title}</NavLink></li>
                    ))}
                    {web3Ctx.account && showHead ?
                      <>
                        <div className="bxAddress d-flex">
                          <div className="">
                              <img className="mt-2" data-src={randomOwnerImg} width="40" alt="" />
                          </div>
                          <a 
                              className="nav-link small pl-2" 
                              href={`${etherscanUrl}/address/${web3Ctx.account}`}
                              target="blank"
                              rel="noopener noreferrer"
                          >
                              {handelAddress}
                          </a>
                        </div>
                      </>
                      :
                      <button 
                          type="button" 
                          className="connect__btn" 
                          onClick={connectWalletHandler}
                      > 
                          {data[0].connect__btn}
                          <img loading="lazy" data-src={logo_MetaMask} alt="MetaMask Logo" className="connect__icon" />
                      </button>
                    }

                </ul>
            </nav>        
        </div>
    </div>
  )
}

export default Head