import { useContext } from 'react';
import data from '../../data/data-containers/data-Home'
import dataJson from '../../data/data-containers/data-Home.json'
import {
  iconsGift
} from "../../utils/allImgs"
import SEO from "../../components/Seo"
import ClaimFunds from "../../components/ClaimFunds"
import Headings from "./Headings"
import InfosAndNumbers from "./InfosAndNumbers"
import Categories from "../../components/Categories"
import ExploreNewNFTs from "../../components/ExploreNewNFTs"
import HowItWorks from "../../components/HowItWorks"
import CollectionContext from '../../store/collection-context';
import Spinner from '../../components/Spinner';

const HomeContainer = () => {

  const collectionCtx = useContext(CollectionContext);

  return (
    <>
      <SEO title="Market NFTs" />
      {/* <ClaimFunds img={iconsGift} /> */}
      <main>
        <Headings data={data[0].dataHeadings} />
        <div className="moving-bg" />
        <div className="moving-bg-2" />
        <InfosAndNumbers data={dataJson[0].dataInfosAndNumbers} />
        <Categories data={data[0].CategoriesDate} />
        {!collectionCtx.nftIsLoading && <ExploreNewNFTs data={data[0].NewNFTs} />}
        {collectionCtx.nftIsLoading && <Spinner />}  
        <HowItWorks title={dataJson[0].HowItWorks} data={data[0].HowItWorks} />
      </main>
    </>
  );
}

export default HomeContainer;