import React from "react"

// import './breadcumb.css'

function BreadcumbContainer({title}){

  return(
    <section className="breadcrumb">
        <div className="container">
            <h2>{title}</h2>
        </div>
    </section>
  )
}

export default BreadcumbContainer