import { useContext } from 'react';
import Breadcumb from '../../components/Breadcumb'
import MyAssetsNewNFTs from "../../components/MyAssetsNewNFTs"
import MsgAlert from "../../components/MsgAlert"
import {iconsNote} from "../../utils/allImgs"
import data from '../../data/data-containers/data-MyAssets'
import SEO from "../../components/Seo"
import CollectionContext from '../../store/collection-context';
import Spinner from '../../components/Spinner';

const MyAssetsContainer = () => {

  const collectionCtx = useContext(CollectionContext);

  return (
    <>
      <SEO title="My Assets" />
      <main>
        <Breadcumb title='My Assets'/>

        {!collectionCtx.nftIsLoading && <MyAssetsNewNFTs data={data[0].NewNFTs} />}
        {collectionCtx.nftIsLoading && <Spinner />}
        <MsgAlert
          img={iconsNote}
          title="No Items Listed For Sale Yet!"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!"
        />
      </main>
    </>
  );
}

export default MyAssetsContainer;
