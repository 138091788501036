import {useState , useEffect} from "react"
import moment from 'moment';
import {
	astronauta1,
	iconsD1,
	iconsD2,
	iconsD3,
	iconsD4,
	iconsD5,
	arrowWhite

} from '../../../utils/allImgs'
import {formatPrice , cutAddress , makeOfferHandler , buyHandler , cancelHandler} from "../../../utils"

const DetailsBlock = ({img , title , text}) => (
  <div className="col-12 col-md-6"> 
    <div className="details-block">
      <div className="details_icon">
        <img loading="lazy" data-src={img} alt="note" width={30} />
      </div>
      <div className="details_info">
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  </div>
)

const DiscoverNFTInDetails = ({data , price}) => {

  const [widthImg , setWidthImg] = useState(0)
  const [heightImg , setHeightImg] = useState(0)
  const [address , setAddress] = useState("")
  const [randomOwnerImg , setRandomOwnerImg] = useState("")
  

  useEffect(() => {
      let imgMint = document.querySelector(".imgFilteredMint")
      if(imgMint !== null && imgMint !== undefined){
          let realWidth = imgMint.clientWidth;
          let realHeight = imgMint.clientHeight;
          setWidthImg(realWidth)
          setHeightImg(realHeight)
      }
      
      // setAddress(cutAddress(data[0]?.owner))
  },[])
  
  const {
    popularOfTheWeek__titleHighlight,
    popularOfTheWeek__title,
    nftImgWrapperImg,
    nft_name,
    img,
    nft_desc,
    detailsBlock,
    PriceItem,
    details_icon,
    details_infoTitle,
    details_infoHighlight
  } = data

  console.log("data of DiscoverNFTInDetails" , data)

  const dateTimeAgo = moment(data[0]?.time ? data[0]?.time : new Date()).fromNow();
  let setHandelAddress = cutAddress(data[0]?.addressOwner)
  let size = `${widthImg} x ${heightImg}`

  return (
    <section className="popularOfTheWeek">
      <h2 className="popularOfTheWeek__title">
        <span className="highlight">{popularOfTheWeek__titleHighlight} </span> {popularOfTheWeek__title}
      </h2>
      <div className="row">
        <div className="col-lg-4 offset-lg-1 col-md-6">
          <div className="popular__card">
            <div className="nft-img-wrapper">
              {data[0]?.img ?
              <img loading="lazy" className="imgFilteredMint" data-src={`https://api.filebase.io/v1/ipfs/${data[0]?.img}`} alt="astronauta 1 NFT" />
              :
              <img loading="lazy" className="imgFilteredMint" data-src={astronauta1} alt="astronauta 1 NFT" />
              }
              
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 mt-s">
          <div className="popular__card">
            <div className="card__header">
              <h4 className="nft_name mt-3">{data[0]?.title}</h4>
            </div>
            <p className="nft_desc">{data[0]?.description}</p>
            <div className="row mt-3">
              <DetailsBlock
                img={iconsD1}
                title="Item Owner"
                text={setHandelAddress}
              />
              <DetailsBlock
                img={iconsD2}
                title="Item Category"
                text={data[0]?.category}
              />
              <DetailsBlock
                img={iconsD3}
                title="NFT Asset Size"
                text={size}
              />
              <DetailsBlock
                img={iconsD4}
                title="Creation Date"
                text={dateTimeAgo}
              />
              <div className="col-12 col-md-12">
                <h4 className="nft_name">{PriceItem}</h4>
                <div className="details-block special">
                  <div className="details_icon">
                    <img loading="lazy" data-src={iconsD5} alt="note" width={30} />
                  </div>
                  <div className="details_info">
                    <h4>Current NFT Price</h4>
                    <p className="highlight">{price ? `${price} ETH` : "Price Not Set Yet!"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DiscoverNFTInDetails;