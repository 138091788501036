const Spinner = () => {
  return(
    <div id="loader-7">
        {Array(20).fill().map((item , key) => (
          <span className="spanLoader"></span>
        ))}
      <h4>Loading Contents</h4>
    </div>
  );
};

export default Spinner;