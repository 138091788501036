import { useContext , useState , useEffect } from 'react';
import Breadcumb from '../../components/Breadcumb'
import AlertDeploy from '../../components/AlertDeploy'
import MintForm from './MintForm'
import {
  mintImg,
  badge,
  iconsCelebration
} from "../../utils/allImgs"
import data from '../../data/data-containers/data-MintForm.json'
import SEO from "../../components/Seo"
import CollectionContext from '../../store/collection-context';
import Spinner from '../../components/Spinner';

const MintNFTContainer = () => {

  const collectionCtx = useContext(CollectionContext);
  const [handleAddSucMsg, setHandleAddSucMsg] = useState(false);

  useEffect(() => {

    if(handleAddSucMsg){
      setTimeout(() => {
        window.location.href = "/explore"
      },3000)
    }
  },[handleAddSucMsg])

  return (
    <>
      <SEO title="Mint" />
      <main>
        <Breadcumb title='Mint NFT' />
        <div className={`${handleAddSucMsg && "hideFormMint"}`}>
          {!collectionCtx.nftIsLoading && <MintForm
            data={data[0].bestArtists__header}
            imgMint={mintImg}
            imgBadge={badge}
            setHandleAddSucMsg={setHandleAddSucMsg}
            />}
          {collectionCtx.nftIsLoading && <Spinner />}
        </div>
        <AlertDeploy
          img={iconsCelebration}
          addSucMsg={handleAddSucMsg}
          title="NFT MINTED SUCCESSFULLY ..."
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique accusantium quae natus odio est ullam ab. Ea iusto corrupti.!"
        />
      </main>
    </>
  );
}

export default MintNFTContainer;